import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import lang from './modules/language'
import books from './modules/books'
import authors from './modules/authors'
import data from './modules/data'
import spinner from './modules/spinner'
import categories from './modules/categories'
import bible from './modules/bible'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
    modules: {
        user,
        lang,
        books,
        authors,
        data,
        spinner,
        categories,
        bible
    },
    strict: debug
})

export default store

