export default {
  name: 'App',

  data: () => ({
    drawer: null,
    menuOptions: [{
        title: "Mis Libros",
        icon: '<v-img class="format_icon mx-2" src="../../assets/icons/home.png" max-width="32px" contain></v-img>',
        to: '/'
      },
      {
        title: "Catálogo",
        icon: "mdi-file-find",
        to: '/finder'
      },
      {
        title: "Lecturas del Día",
        icon: "mdi-torch",
        to: '/bible'
      },
    ],
    links: [{
        title: "Mis Libros",
        icon: "mdi-home",
        to: '/'
      },
      {
        title: "Catálogo",
        icon: "mdi-file-find",
        to: '/finder'
      },
      {
        title: "Lecturas del Día",
        icon: "mdi-torch",
        to: '/bible'
      }
    ],
    
    collapseOnScroll: false,
    userInfo: {
      displayName: "Usuario",
      photos: ["../../assets/avatar.png"]
    },
    spinner: false
  }),
  beforeMount() {
    this.$store.dispatch('setSpinner', true);
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("fetchUserInfo")
      ])
      this.userInfo = this.$store.getters.getUserInfo ? this.$store.getters.getUserInfo : this.userInfo;
    } catch (error) {
      console.log(error);
      // alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
    } finally {
      this.$store.dispatch('setSpinner', false);
    }
  },
  methods: {
    openCategories(book) {
      let bookTitleUrl = book.title.toLowerCase().split(" ").join("_");
      this.$router.push({
        name: "Book",
        params: {
          book: book,
          bookTitleUrl: bookTitleUrl
        }
      })
    },
    logout() {
      try {
        this.$store.dispatch('setSpinner', true);
        this.$store.dispatch("fetchUserLogout");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.dispatch('setSpinner', false);
        const api_base_url = process.env.VUE_APP_API_BASE_URL || 'http://localhost:8081';
        window.location = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" + api_base_url;

      }
    }
  }
};