import axios from "axios";

const api_base_url = process.env.VUE_APP_API_BASE_URL || 'http://localhost:8080';

const httpClient = axios.create({
    baseURL: api_base_url + "/api",
    headers: {
        "Content-type": "application/json",

    },
    withCredentials: true,
    crossDomain: true
});

httpClient.interceptors.response.use(undefined, error => {
    console.log(error.response);
    if (error.response.status === 401) {
        // Si usuario no autenticado redirect a login de google
        window.location = api_base_url + "/api/auth/google";
    }
    return Promise.reject(error);
});

const pdfClient = axios.create({
    responseType: 'arraybuffer',
    // responseType: 'blob',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf'
    },
    withCredentials: true,
    crossDomain: true
});

pdfClient.interceptors.response.use(undefined, error => {
    console.log(error.response);
    if (error.response.status === 401) {
        // Si usuario no autenticado redirect a login de google
        window.location = api_base_url + "/api/auth/google";
    }
    return Promise.reject(error);
});


export default httpClient;
export { pdfClient };