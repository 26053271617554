<template>
  <v-overlay :value="spinner">
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default{
    name: 'spinner',
    computed: {
        spinner() {
            return this.$store.getters.getSpinner;
        }
    }
}
</script>
