/* DATA */
export const SET_RESULT_ALL_BOOKS = 'SET_RESULT_ALL_BOOKS';
export const SET_RESULT_ALL_AUTHORS = 'SET_RESULT_ALL_AUTHORS';
export const SET_RESULT_USER_INFO = 'SET_RESULT_USER_INFO';
export const SET_RESULT_USER_LOGOUT = 'SET_RESULT_USER_LOGOUT';
export const SET_RESULT_ALL_BOOKS_CATEGORY = 'SET_RESULT_ALL_BOOKS_CATEGORY';
export const SET_RESULT_ALL_CATEGORIES = 'SET_RESULT_ALL_CATEGORIES';
export const SET_RESULT_USER_POSITIONS = 'SET_RESULT_USER_POSITIONS';
export const SET_RESULT_BIBLE = 'SET_RESULT_BIBLE';
export const SET_RESULT_MUSIC_PLAYLIST = 'SET_RESULT_MUSIC_PLAYLIST';
export const SET_SPINNER = 'SET_SPINNER';
