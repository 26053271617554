//import Vue from 'vue'
import * as types from '@/store/mutations-types'

const _state = {
    active: false
};
const _getters = {
    getSpinner(state){
        return state.active;
    }
};
const _actions = {
    setSpinner({commit}, status){
        commit(types.SET_SPINNER, {status});
    }
};
const _mutations = {
    [types.SET_SPINNER](state, {status}) {
        state.active = status;
    }
};

export default ({
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
});