//import Vue from 'vue'
import * as types from '@/store/mutations-types'
import dataServices from '../../services/data';

// ########################
// state
// ########################
const _state = {
    books: [],
    authors: [],
    userInfo: null,
    userPositions: null,
    bible: null,
    music_playlist: []
};

// ########################
// getters
// ########################
const _getters = {
    getAllBooks: state => state.books,
    getAllAuthors: state => state.authors,
    getUserInfo: state => state.userInfo,
    getUserLogout: state => state.userLogout,
    getUserPositions: state => state.userPositions,
    getAllCategories: state => state.categories,
    getAllBooksByCategory: state => category => state.books.filter(b => b.tag.find(t => t.name == category) == true),
    getBible: state => state.bible,
    getMusicPlaylist: state => state.music_playlist
};

// ########################
// actions
// ########################
const _actions = {
    async fetchAllBooks({
        commit
    }) {
        const result = await dataServices.getAllBooks();
        const dataResult = result.data.sort((a, b) => a.title.localeCompare(b.title));

        commit(types.SET_RESULT_ALL_BOOKS, {
            dataResult
        });
    },
    async fetchAllAuthors({
        commit
    }) {
        const result = await dataServices.getAllAuthors();
        const dataResult = result.data.map(a => {
            a.name_formated = a.name.includes(',') ? a.name.split(", ").reverse().join(" ") : a.name;
            return a;
        }).sort((a, b) => a.name_formated.localeCompare(b.name_formated));

        commit(types.SET_RESULT_ALL_AUTHORS, {
            dataResult
        });
    },
    async getBookById(id) {
        const [result] = await dataServices.get(id);
        return result;
    },
    async fetchUserInfo({
        commit
    }) {
        const result = await dataServices.getUserInfo();
        const dataResult = result.data;

        commit(types.SET_RESULT_USER_INFO, {
            dataResult
        });
    },
    async fetchUserLogout({
        commit
    }) {
        const result = await dataServices.getUserLogout();
        const dataResult = result.data;

        commit(types.SET_RESULT_USER_LOGOUT, {
            dataResult
        });
    },
    async fetchUserPositions({
        commit
    }) {
        const result = await dataServices.getUserPositions();
        const dataResult = result.data;

        commit(types.SET_RESULT_USER_POSITIONS, {
            dataResult
        });
    },
    async fetchAllCategories({
        commit
    }) {
        const result = await dataServices.getAllCategories();
        const dataResult = result.data.sort((a, b) => a.name.localeCompare(b.name));

        commit(types.SET_RESULT_ALL_CATEGORIES, {
            dataResult
        });
    },
    async fetchAllBooksByCategory({
        commit
    }, id) {
        const result = await dataServices.getAllBooksByCategory(id);
        const dataResult = result.data;

        commit(types.SET_RESULT_ALL_BOOKS, {
            dataResult
        });
    },
    async getBookByTitle(title) {
        title = title.toUpperCase();
        const result = await dataServices.getBookByTitle(title);
        return result;
    },
    async fetchBibleToday({
        commit
    }) {
        const result = await dataServices.getBibleToday();
        const dataResult = result.data;
        commit(types.SET_RESULT_BIBLE, {
            dataResult
        });
    },
    async fetchMusicPlaylist({
        commit
    }) {
        const result = await dataServices.getMusicPlaylist();
        const dataResult = result.data;
        commit(types.SET_RESULT_MUSIC_PLAYLIST, {
            dataResult
        });
    },
    async deleteBookFromList({state}, book) {
        console.log(state.userPositions);
        if(book.ext == "pdf"){
            await dataServices.deleteUserPosition(book.id,"PDF");
        } else if(book.ext == "epub"){
            await dataServices.deleteUserPosition(book.id,"EPUB");
        }
    }
};

// ########################
// mutations
// ########################
const _mutations = {
    [types.SET_RESULT_ALL_BOOKS](state, {
        dataResult
    }) {
        state.books = dataResult ? dataResult : [];
    },
    [types.SET_RESULT_ALL_AUTHORS](state, {
        dataResult
    }) {
        state.authors = dataResult ? dataResult : [];
    },
    [types.SET_RESULT_USER_INFO](state, {
        dataResult
    }) {
        state.userInfo = dataResult ? dataResult : [];
    },
    [types.SET_RESULT_USER_POSITIONS](state, {
        dataResult
    }) {
        state.userPositions = dataResult ? dataResult : [];
    },
    [types.SET_RESULT_ALL_CATEGORIES](state, {
        dataResult
    }) {
        state.categories = dataResult ? dataResult : [];
    },
    [types.SET_RESULT_ALL_BOOKS_CATEGORY](state, {
        dataResult
    }) {
        state.books = dataResult ? dataResult : [];
    },
    [types.SET_RESULT_BIBLE](state, {
        dataResult
    }) {
        state.bible = dataResult ? dataResult : [];
    },
    [types.SET_RESULT_MUSIC_PLAYLIST](state, {
        dataResult
    }) {
        state.music_playlist = dataResult ? dataResult : [];
    }
};

export default ({
    state: _state,
    getters: _getters,
    actions: _actions,
    mutations: _mutations
});