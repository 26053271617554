import http from "../http-common";

const dataServices = {
  async getAllBooks() {
    return http.get("/books");
  },

  async get(id) {
    return http.get(`/books/${id}`);
  },

  async findByTitle(title) {
    return http.get(`/books?title=${title}`);
  },

  async getUserInfo() {
    return http.get(`/auth/me`);
  },

  async getUserLogout() {
    return http.get(`/auth/logout`);
  },

  async getUserPositions() {
    return http.get(`/users/me/book-position`);
  },

  async saveUserPositions(book, format, cfi, pos_frac) {
    const data = {
      "book": book,
      "format": format,
      "device": "1",
      "cfi": cfi,
      "pos_frac": pos_frac

    }
    return http.patch(`/users/me/book-position`, data);
  },

  async deleteUserPosition(book, format) {
    return http.delete(`/users/me/book-position?book=${book}&format=${format}`);
  },

  async getAllCategories() {
    return http.get("/tags");
  },

  async getAllBooksByCategory(id) {
    //TODO: sql injection?
    return http.get("/tags/" + id + "/books");
  },

  async getAllAuthors() {
    return http.get("/authors");
  },
  async getBibleToday() {
    return http.get("/bible/today");
  },
  async getMusicPlaylist() {
    return http.get("/media/music");
  },
}

export default dataServices;
