import Vue from "vue";
import Router from "vue-router";

// Importar vistas
const home = () => import('@/views/Home');
const finder = () => import('@/views/Finder');
const book = () => import('@/views/Book');
const bible = () => import('@/views/Bible');
const music = () => import('@/views/Music');
const bookReader = () => import('@/views/BookReader');
const bookEpubReader = () => import('@/views/BookEpubReader');
const categories = () => import('@/views/Categories');
const booksByCategory = () => import('@/views/BooksByCategory');


Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: home
    },
    {
      path: "/book/:bookID",
      name: 'Book',
      props: true,
      component: book
    },
    {
      path: "/bookreader/:bookID",
      name: 'BookReader',
      props: true,
      component: bookReader
    },
    {
      path: "/bookEpubreader/:bookID",
      name: 'BookEpubReader',
      props: true,
      component: bookEpubReader
    },
    {
      path: "/finder",
      name: 'Finder',
      props: true,
      component: finder
    },
    {
      path: "/categories",
      name: 'Categories',
      props: true,
      component: categories
    },
    {
      path: "/booksbycategory",
      name: 'BooksByCategory',
      props: true,
      component: booksByCategory
    },
    {
      path: "/bible",
      name: 'Evangelio',
      props: true,
      component: bible
    },
    {
      path: "/music",
      name: 'Music',
      props: true,
      component: music
    },
  ]
});
